@import "App/variables.css";

* {
	box-sizing: border-box;
}

body {
	font-size: var(--standard-size);
	line-height: calc(5.5 * var(--bu));
	font-family: "usual", "Helvetica Neue", "Helvetica", sans-serif;
	letter-spacing: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration: none;
	color: var(--brand);
	cursor: pointer;
}

a {
	color: var(--brand);
	text-decoration: none;
	transition: 200ms all ease-out;
}

a:hover {
	color: var(--brand);
	opacity: 0.9;
	text-decoration: none !important;
}

img {
	max-width: 100%;
}

font {
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}

.background-renderer.white,
.background-renderer.white #page-header-container,
.background-renderer.white footer {
  background: white;
}

.background-renderer.colored,
.background-renderer.colored #page-header-container,
.background-renderer.colored footer {
	background: var(--alternate-brand);
}

#primary-nav.navbar {
	min-height: 0;
	margin-bottom: 0;
}

#primary-nav .navbar-left {
	margin-left: -15px;
}

#primary-nav.navbar-default .navbar-nav>li>a {
	padding-top: calc(2 * var(--bu));
	padding-bottom: calc(2 * var(--bu));
}

#page-header-container {
	background: var(--alternate-brand);
}

.page-header {
	border-bottom: none;
	padding: calc(2 * var(--bu)) 0 calc(2 * var(--bu));
	margin: 0 0 calc(5 * var(--bu));
}

.page-header h1 {
  font-weight: 400;
  margin-bottom: 0;
}

.page-header h1 a {
  color: #000;
  display: block;
  width: 308px;
  height: 0;
  overflow: hidden;
  padding-top: 91px;
  background: url(./logo.png);
  background-size: 308px 91px;
}

.page-header h1 small {
  display: block;
  margin-top: 0.2em;
  font-size: 0.5em;
  color: #aaa;
  font-style: italic;
  font-family: Lora, serif;
}

#hero-image {
	max-height: calc(150 * var(--bu));
	overflow: hidden;
}

#main-content-wrapper {
	background: #fff;
	border-top: 1px solid var(--medium-border-color);
}

#hero-p {
	font-size: calc(4 * var(--bu));
	line-height: calc(6 * var(--bu));
	text-align: center;
	margin-top: calc(6 * var(--bu));
}

span.required {
	font-weight: 400;
	color: var(--medium-text-color);
}

footer {
	text-align: center;
	padding-top: calc(10 * var(--bu));
	padding-bottom: calc(10 * var(--bu));
	border-top: 1px solid var(--medium-border-color);
	background: var(--alternate-brand);
}

footer p {
	margin-top: var(--bu);
	margin-bottom: 0;
}

.control-label {
	text-align: left !important;
}

a.is-active { 
	font-weight: 700;
}

.nav a.is-active {
	color: white !important;
}

#loading-container {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0; 
	left: 0;
	z-index: 10;
}

#loading-backdrop {
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.25);
}

#loading-content {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 11;
	display: flex;
	justify-content: center;
	align-items: center;
}

#loading-content-inner {
	text-align: center;
	padding: 20px;
	background: #fff;
	border-radius: calc(3 * var(--bu));
}

.dropdown-content input[type="checkbox"] {
	position: relative;
	margin: 0 !important;
	margin-right: 10px !important;
}

label div.item-renderer {
	display: flex;
	flex-direction: row;
	align-items: center;
}