@import "App/variables.css";

.loading-wrapper {
  margin-top: calc(3 * var(--bu));
}

.items-grid {
	margin-left: calc(-3 * var(--bu));
	margin-right: calc(-3 * var(--bu));
	margin-top: calc(5 * var(--bu));
	margin-bottom: calc(5 * var(--bu));
}

.library-item {
	padding: calc(2 * var(--bu));
  display: block;
}

.library-item figure {
  width: 100%;
  border-radius: calc(2 * var(--bu));
  display: block;
  margin: 0;
  transition: 200ms all ease-out;
  position: relative;
  overflow: hidden;
  background: var(--alternate-brand);
  z-index: 2;
}

.library-item-image-wrapper {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 100px;
}

.library-item:hover h4 {
  color: var(--brand);
}

.library-item img {
  width: 100%;
  display: block;
  transition: 200ms opacity ease-out, 0.5s transform ease;
}

.library-item:hover figure img {
  transform: scale(1.1);
  z-index: 0;
}

.item-summary h4 {
  margin: 0;
  padding: calc(0.75 * var(--bu)) calc(2 * var(--bu)) calc(0.25 * var(--bu));
  font-size: calc(2.75 * var(--bu));
  text-transform: uppercase;
  color: #666;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-align: center;
}

.item-summary ul {
  margin: 0;
  padding: 0;
}

.item-summaryli {
  list-style: none;
  margin: 0;
  text-transform: capitalize;
}

.no-items {
  padding-top: calc(7 * var(--bu));
}

.pagination-info {
  margin-top: calc(7 * var(--bu));
  padding-left: 0;
	margin-bottom: 0;
	display: block;
}

.pages-bottom {
  margin-bottom: calc(7 * var(--bu));
  clear: both;
}

.pagination-info li {
	display: inline;
}

.pages-bottom li {
  display: list-item;
  list-style: none;
}

.sale-banner {
  position: absolute;
  top: 14px; 
  left: -22px; 
  transform: rotate(-45deg); 
  width: 100px; 
  text-align: center; 
  background: rgba(255,255,255,0.95); 
  font-size: 11px; 
  text-transform: uppercase; 
  color: red; 
  font-weight: bold; 
  letter-spacing: 1px
}