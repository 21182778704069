.about {
  background: #DFECEE;
  padding-top: 100px;
  padding-bottom: 100px;
}
.container {
  background: #fff;
}
.about h2 {
  text-align: center;
  padding-top: 40px;
}
.button {
  margin: 52px auto 60px;
  text-align: center;
}
.button a {
  padding: 12px 30px;
  background: #ca584d;
  color: #fff;
  font-weight: 600;
  border-radius: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.button a:hover {
  background: #e1a29c;
}