@import "App/variables.css";

/* a.active{
  font-weight: bold;
}
#show-hide-filters {
  display: none;
}
@media (max-width:991px) {
  #show-hide-filters {
    display: block;
  }
} */

.category-nav {
	padding-bottom: calc(3* var(--bu));
	background: var(--alternate-brand);
}

.category-nav ul {
  margin-bottom: 0;
  padding-left: 0;
}

.category-nav li {
	list-style: none;
	display: inline-block;
	margin-right: calc(5* var(--bu));
}

.category-nav li a {
	color: #000;
}

.category-nav li a:hover {
	color: var(--brand);
}