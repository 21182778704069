@import "App/variables.css";

.scrollbox {
  overflow: scroll;
  width: 100%;
  height: 400px;
  padding: 0;
  background: #000;
  border: 0;
}

.photosWrapper {
  display: flex;
  width: max-content;
  height: 400px;
  overflow-y: hidden;
  margin-left: 100px;
  transition: 1s margin ease;
}

.scrollbox a {
  display: inline-block;
  height: 400px;
  overflow: hidden;
}

.scrollbox img {
  max-height: 400px;
}

.scrollbox-entered .photosWrapper {
  margin-left: 0;
}

.main-content {
  background: var(--alternate-brand);
  padding-top: 100px;
  padding-bottom: 100px;
}
    
.container {
  background: #fff;
}

.item-details {
	padding: 0;
	margin-top: 2em;
	margin-bottom: 2em;
	width: 100%;
}

.item-details tr {
		text-transform: capitalize;
}

.td-key {
  width: calc(35 * var(--bu));
  vertical-align: top;
  font-weight: bold;
  text-align: right;
  padding: calc(1.25 * var(--bu)) 0 calc(0.75 * var(--bu));
  padding-right: calc(2 * var(--bu));
  border-right: 1px solid var(--medium-border-color);
}

.td-value {
  padding: calc(1.25 * var(--bu)) calc(2 * var(--bu)) calc(0.75 * var(--bu));
}

.contact {
  margin-top: 1em;
}

.manage-buttons {
  padding-top: 16px;
}

.manage-buttons a {
  margin-right: 12px;
  margin-bottom: 12px;
}