@import "App/variables.css";

.filter-form {
  padding-top: calc(2 * var(--bu));
  padding-right: calc(2 * var(--bu));
  padding-bottom: calc(4 * var(--bu));
}

.filter-title {
  font-weight: 600;
  margin-bottom: 0;
  margin-top: calc(5* var(--bu))
}

.slider-labels {
  font-size: calc(0.8 * var(--standard-size)) !important;
  color: var(--medium-text-color);
}

.slider-primary-color {
  color: var(--brand) !important;
}

.select-field > div {
  width: 100%;
}

.select-field-item {
  font-size: calc(0.9 * var(--standard-size));
  line-height: calc(1.2 * var(--standard-size));
}

.select-field-option {
  font-size: calc(0.9 * var(--standard-size)) !important;
  margin-bottom: var(--bu) !important;
}

.range {
  width: calc(100% - calc(1.5*var(--bu))) !important;
  margin-bottom: 0 !important;
}