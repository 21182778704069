:root {
  --bu: 4px;
  --brand: #ca584d;
  --alternate-brand: #DFECEE;
  --medium-border-color: #CEE0E6;
  --medium-text-color: #999;
  --border-color: #ddd;

  --standard-size: calc(3.5 * var(--bu));

  --shadow: 0 .25*var(--bu) var(--bu) var(--shadow-color);
  --radius: var(--bu);
}