
.content {
  background: #DFECEE;
  padding-top: 100px;
  padding-bottom: 100px;
}
.container {
  background: #fff;
  padding-top: 40px;
  padding-bottom: 100px;
}
.content h3 {
  margin-bottom: 32px;
  margin-top: 60px;
}
.content h4 {
  margin-bottom: 20px;
  margin-top: 32px;
}