.wrapper {
  padding: 20px 0 40px;
}

.figure {
  border-radius: calc(2 * var(--bu));
  overflow: hidden;
}

.file-input {
	background: #fff;
	border: 1px solid #ddd;
	padding: calc(2.25 * var(--bu)) calc(2 * var(--bu)) calc(1.75 * var(--bu));
	color: #999;
	border-radius: 4px;
}

.order-input {
  display: inline !important;
}

.gallery-item {
  margin-bottom: 20px;
}

.photo-action-row {
  margin: 8px 0;
}

.photo-actions {
  height: 100px;
}